/* ------------------------------ */
/* General
/* ------------------------------ */   
body {
    background-color: #F8F8F8 !important;
}
body.loading:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
    z-index: 9998;
}
body.loading:after {
    content: '';
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    background-image: url(../../public/assets/img/loader.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 9999;
    background-position-y: center;
}
main {
    font-family: Montserrat;
    display: block;
    left: 0;
}
.clear-margin-padding {
    margin: 0 !important;
    padding: 0 !important;
}
.text-center {
    text-align: center;
}
.half-width {
    width: 50%;
}
.full-width {
    width: 100%;
}
.hide {
    display: none !important;
}
.show {
    display: block;
}
.container {
    max-width: 100%;
    padding: 18px;
}
.pac-container {
    z-index: 3333;
    background-color: #ffffff;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.row-center {
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
}
.flex-grow {
    flex-grow: 1;
}
.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}
.relative {
    position: relative;
}
.full-center {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-15 {
    margin: 15;
}
/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.cat-label {
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    top: 0px;
}
@media (max-width: 767px) {
    main {
        max-width: 100vw;
        overflow-x: hidden;
    }
}
.cms-container {
    padding: 0 16px;
    text-align: justify;
}
.cms-container .cms-content-important {
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
}
.cms-container a {
    color: #006bb4;
}
.cms-container a:hover,
.cms-container a:active {
    color: #444;
}
.cms-container .social-icon {
    padding: 0 6px;
    vertical-align: middle;
    display: inline-block;
}
.cms-container .social-icon i:before {
    content: '';
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: block;
}
.cms-container .social-icon .icon-facebook:before {
    background-image: url(../../public/assets/img/facebook.png);
}
.cms-container .social-icon .icon-twitter:before {
    background-image: url(../../public/assets/img/twitter.png);
}
.cms-container .social-icon .icon-instagram:before {
    background-image: url(../../public/assets/img/instagram.png);
}
.cms-container .social-icon .icon-pinterest:before {
    background-image: url(../../public/assets/img/pinterest.png);
}
.wrapper-brands .slick-track{
    display: flex;
    align-items: center;
}

.grid {
    background-color: #ffffff;
}

.collapsible {
    background-color: #DEDEDE;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.active, .collapsible:hover {
    background-color: #F8F8F8;
}

.content-collapsible {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #FFFFFF;
}